import React, { useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import MainContainer from "./MainContainer";
import SectionHeader from "./SectionHeader";
import { CustomInputField, CustomTextArea } from "./ReusableComponents";
import axios from "axios";
import PropTypes from "prop-types";
import { useParams ,useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from '../contexts/AuthContext';
const AddCustomerForm = () => {
    const { auth } = useAuth();
    const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;

    console.log("auth in Customer Form", auth);
    const { id } = useParams();
    const navigate = useHistory();
    
// Initial state for form data

const [formData, setFormData] = useState({
    nameSuffix: '',
    firstName: '',
    lastName: '',
    customerDisplayName: '',
    companyName: '',
    registrationNumber: '',
    phone: '',
    whatsapp: '',
    email: '',
    currencyCode: '',
    paymentTerms: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    stateAbbr: '',
    sameAsBilling: false
});

// const [error, setError] = useState("");

const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
    });
};

// const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Form Data:", formData);
// };

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const customerData = { ...formData };
        const apiReqHeaders = {
            headers: {
                Authorization: `Bearer ${auth}`,
                'Content-Type': 'application/json',
            },
        };

        if (id) {
            await axios.put(`${API_BASE_URL}/customers/${id}`, customerData, apiReqHeaders);
            alert('Customer updated successfully!');
        } else {
            await axios.post(`${API_BASE_URL}/customers`, customerData, apiReqHeaders);
            alert('Customer created successfully!');
        }

        navigate('/dashboard/customers');
    } catch (error) {
        // setError('Failed to save customer.');
        console.error('Error saving customer:', error);
    }
};

return (
    <>
        <Header />
        <Sidebar />
        <MainContainer>
            <SectionHeader sectionHeading="Add Customer" />
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="form-title col-lg-12">
                        <h5>Basic Details</h5>
                    </div>
                    <CustomInputField
                        labelText="Name Suffix"
                        name="nameSuffix"
                        value={formData.nameSuffix}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="Customer Display Name"
                        name="customerDisplayName"
                        value={formData.customerDisplayName}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="Company Name"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="Registration Number"
                        name="registrationNumber"
                        inputType="number"
                        value={formData.registrationNumber}
                        onChange={handleChange}
                    />
                    {/* Replace PhoneField with simple input fields */}
                    <CustomInputField
                        inputType="text"
                        labelText="Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        inputType="text"
                        labelText="Whatsapp Number"
                        name="whatsapp"
                        value={formData.whatsapp}
                        onChange={handleChange}
                    />

                    <CustomInputField
                        inputType="email"
                        labelText="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        inputType="number"
                        labelText="Currency Code"
                        name="currencyCode"
                        value={formData.currencyCode}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="Payment Terms"
                        name="paymentTerms"
                        value={formData.paymentTerms}
                        onChange={handleChange}
                    />
                    <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                        <h5>Shipping Address</h5>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 my-4">
                        <div className="d-flex align-items-baseline">
                            <h5>Same as Billing Address</h5>
                            <span>
                                <span className="d-inline-block ms-2 me-1">
                                    <input
                                        type="checkbox"
                                        name="sameAsBilling"
                                        checked={formData.sameAsBilling}
                                        onChange={handleChange}
                                    />
                                </span>
                                <span>Same as billing address</span>
                            </span>
                        </div>
                    </div>
                    {/* {error && (
                                            <p className="text-danger">
                                                {error}
                                            </p>
                                        )} */}
                    <CustomInputField
                        labelText="Address Line 1"
                        name="addressLine1"
                        value={formData.addressLine1}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="Address Line 2"
                        name="addressLine2"
                        value={formData.addressLine2}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="City"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="State"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                    />
                    <CustomInputField
                        labelText="State Abbr"
                        name="stateAbbr"
                        value={formData.stateAbbr}
                        onChange={handleChange}
                    />
                    <div className="col-lg-12">
                        <CustomTextArea
                            labelText="Note"
                            name="note"
                            value={formData.note}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary mt-4">
                    Save
                </button>
            </form>
        </MainContainer>
    </>
);
};

AddCustomerForm.propTypes = {
    auth: PropTypes.string.isRequired
};

export default AddCustomerForm;
